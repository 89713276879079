import React, { useEffect, useState } from "react";
import {
  DropdownInput,
  Input,
  PrimaryButton,
  Title,
  Box,
  Button,
  Flex,
  Grid,
  Skeleton,
  Text,
} from "@samedaycustom/core-ui";
import * as Yup from "yup";
import { Formik } from "formik";

import { ReactComponent as Copy } from "packages/assets/images/copy.svg";
import { ReactComponent as Link } from "packages/assets/images/link.svg";
import { ReactComponent as Circle } from "packages/assets/images/circle.svg";
import { ReactComponent as Location } from "packages/assets/images/location_c.svg";
import { ReactComponent as LocationDanger } from "packages/assets/images/location-danger.svg";

import { ICart } from "@samedaycustom/sdc-types";
import moment from "moment";
import { textToSentenceCase } from "@samedaycustom/helpers/src";

export interface ICartDetailsProps {
  getCart: (id: string, locationId: string, domain?: string) => Promise<void>;
  loading: boolean;
  id: string;
  updateCart: (data: Partial<ICart>) => Promise<void>;
  getAssignees?: () => void;
  assignees?: { label: string; value: string }[];
  cart: ICart;
  customerStoreUrl: string;
  locationId: string;
  domain?: string;
}

export default ({
  getCart,
  loading: isLoading,
  id,
  locationId,
  updateCart,
  getAssignees,
  assignees: users,
  cart,
  customerStoreUrl,
  domain,
}: ICartDetailsProps) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const assignees = users.map((user: any) => ({
    label: textToSentenceCase(user.firstName),
    value: user.firstName,
  }));

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    return;
  };

  const onLink = (text: string) => {
    window.open(text, "_blank");
  };

  const statusList = [
    { label: "Waiting on customer", value: "waiting_on_customer" },
    { label: "Waiting designer", value: "waiting_designer" },
    { label: "Contacted", value: "contacted" },
    { label: "Negotiation", value: "negotiation" },
    { label: "Abandoned", value: "abandoned" },
    { label: "Purchased", value: "purchased" },
    { label: "Unassigned", value: "unassigned" },
  ];

  const onsubmit = async (values: any, { resetForm }: any) => {
    const note = {
      note: values.note,
      createdAt: Date.now(),
    };

    const notes = Array.isArray(cart?.agentNote) ? cart.agentNote : [];
    await updateCart({
      userId: cart.userId,
      agentNote: [note as any, ...notes],
      locationId: cart.locationId,
    });
    resetForm({ status: values.status, agent: values.agent, note: "" });
    setIsFocus(false);
    getCart(id, locationId, domain);
    return;
  };

  useEffect(() => {
    id && locationId && getCart(id, locationId, domain);
    getAssignees();
  }, [id, locationId, domain]);

  return (
    <React.Fragment>
      <Box>
        {isLoading ? (
          <Skeleton width={200} height={15} marginBottom="15px" />
        ) : (
          <Text>
            {customerStoreUrl}/cart/{cart?.code}
          </Text>
        )}
        {isLoading ? (
          <Skeleton width={100} height={15} />
        ) : (
          <Flex marginTop="10px" alignItems="center">
            <Text
              color="#007BFF"
              cursor="pointer"
              fontFamily="Inter"
              fontWeight={500}
              width="fit-content"
              marginRight="10px"
              onClick={() => cart?.code && onLink(`${customerStoreUrl}/cart/${cart?.code}`)}
            >
              <Flex alignItems="center">
                <Link />
                <Text marginLeft="2px">Visit link</Text>
              </Flex>
            </Text>
            <Text
              color="#007BFF"
              cursor="pointer"
              fontFamily="Inter"
              fontWeight={500}
              width="fit-content"
              onClick={() => cart?.code && onCopy(`${customerStoreUrl}/cart/${cart?.code}`)}
            >
              <Flex alignItems="center">
                <Copy />
                <Text marginLeft="2px">Copy link</Text>
              </Flex>
            </Text>
          </Flex>
        )}
      </Box>
      <Grid marginY="24px" templateColumns="repeat(3, 1fr)" rowGap="20px" columnGap="20px">
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.contactDetails?.firstName && (
            <Box>
              <Title>Full Name</Title>
              <Text>
                {cart?.contactDetails?.firstName} {cart?.contactDetails?.lastName}
              </Text>
            </Box>
          )
        )}

        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.contactDetails?.mobile && (
            <Box>
              <Title>Phone Number</Title>
              <Text>{cart?.contactDetails?.mobile}</Text>
            </Box>
          )
        )}
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          (cart?.email || cart?.contactDetails?.email) && (
            <Box>
              <Title>Email Address</Title>
              <Text>{cart.email || cart?.contactDetails?.email}</Text>
            </Box>
          )
        )}
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.checkoutProgress && (
            <Box>
              <Title>Checkout Progress</Title>
              <Text>{cart?.checkoutProgress}</Text>
            </Box>
          )
        )}
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.billableAmount && (
            <Box>
              <Title>Cart Value</Title>
              <Text>
                $
                {cart?.billableAmount -
                  cart?.billableAmount * ((cart?.coupon?.percentOff ?? 0) / 100)}
              </Text>
            </Box>
          )
        )}
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.coupon && (
            <Box>
              <Title>Coupon Name</Title>
              <Text>{cart?.coupon?.couponName}</Text>
            </Box>
          )
        )}
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.coupon && (
            <Box>
              <Title>Coupon Code</Title>
              <Text>{cart?.coupon?.couponCode}</Text>
            </Box>
          )
        )}
        {isLoading ? (
          <Box>
            <Skeleton height={15} width={70} />
            <Skeleton height={15} width={120} />
          </Box>
        ) : (
          cart?.coupon && (
            <Box>
              <Title>Coupon Discount</Title>
              <Text>
                {cart?.coupon?.percentOff ?? 0}% off - $
                {cart?.billableAmount * ((cart?.coupon?.percentOff ?? 0) / 100)}
              </Text>
            </Box>
          )
        )}
      </Grid>
      {isLoading ? (
        <Skeleton height={40} width={560} />
      ) : (
        <Grid marginBottom="24px" templateColumns="1fr 1fr" columnGap="20px">
          <Box backgroundColor="#F0F7FF" padding="12px" rounded="15px">
            {cart?.userLocation?.city ? (
              <>
                <Title>Customer Location</Title>
                <Text marginBottom="7px">
                  {cart?.userLocation?.city}, {cart?.userLocation?.state}
                </Text>
                <Flex flexWrap="wrap">
                  {cart?.store?.distanceToOrder && (
                    <Text marginRight="5px">
                      <Flex alignItems="center">
                        <Location />{" "}
                        <Text marginLeft="5px" fontSize="11" fontWeight={500}>
                          {" "}
                          {cart?.store?.distanceToOrder} miles from store
                        </Text>
                      </Flex>
                    </Text>
                  )}
                  {cart?.store?.pickupAvailable && (
                    <Text marginRight="5px">
                      <Flex alignItems="center">
                        <Circle />{" "}
                        <Text marginLeft="5px" fontSize="11" fontWeight={500}>
                          Pickup Available
                        </Text>
                      </Flex>
                    </Text>
                  )}
                </Flex>
              </>
            ) : (
              <Box textAlign="center">
                <Flex justifyContent="center" marginBottom="12px">
                  <LocationDanger />
                </Flex>
                <Text>Customer location not available</Text>
              </Box>
            )}
          </Box>
          <Box backgroundColor="#F0F7FF" padding="12px" rounded="15px">
            {cart?.store?.city ? (
              <>
                <Title>Store Location</Title>

                <Text marginBottom="7px">
                  {cart?.store?.city}, {cart?.store?.state}
                </Text>
                <Flex>
                  {cart?.store?.pickupAvailable && (
                    <Text marginRight="5px">
                      <Flex alignItems="center">
                        <Circle />{" "}
                        <Text marginLeft="5px" fontSize="11" fontWeight={500}>
                          Pickup
                        </Text>
                      </Flex>
                    </Text>
                  )}
                  {cart?.store?.pickupAvailable && (
                    <Text marginRight="5px">
                      <Flex alignItems="center">
                        <Circle />{" "}
                        <Text marginLeft="5px" fontSize="11" fontWeight={500}>
                          Car Delivery
                        </Text>
                      </Flex>
                    </Text>
                  )}
                  {
                    <Text marginRight="5px">
                      <Flex alignItems="center">
                        <Circle />{" "}
                        <Text marginLeft="5px" fontSize="11" fontWeight={500}>
                          Shipping
                        </Text>
                      </Flex>
                    </Text>
                  }
                </Flex>
              </>
            ) : (
              <Box textAlign="center">
                <Flex justifyContent="center" marginBottom="12px">
                  <LocationDanger />
                </Flex>
                <Text>Store location not available</Text>
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {cart && (
        <Formik
          initialValues={{
            status: cart.orderStatus,
            agent: cart.agent,
            note: "",
          }}
          validationSchem={Yup.object({
            note: Yup.string().required(),
          })}
          onSubmit={onsubmit}
        >
          {({ handleSubmit, isValid, setFieldValue, isSubmitting }) => (
            <React.Fragment>
              <Grid templateColumns="1fr 1fr" columnGap="20px">
                <Box>
                  <DropdownInput
                    label="Status"
                    asyncAction={() => {}}
                    externalChangeFunc={async (value) => {
                      setFieldValue("status", value.value);
                      await updateCart({
                        orderStatus: value.value,
                        userId: cart.userId,
                        locationId: cart.locationId,
                      });
                    }}
                    list={statusList}
                    placeholder="Unassigned"
                    name="status"
                    loading={false}
                  />
                </Box>
                <Box>
                  <DropdownInput
                    label="Assign to"
                    asyncAction={() => {}}
                    externalChangeFunc={async (value) => {
                      setFieldValue("agent", value.value);
                      await updateCart({
                        agent: value.value,
                        userId: cart.userId,
                        locationId: cart.locationId,
                      });
                    }}
                    list={assignees}
                    placeholder="Unassigned"
                    name="agent"
                    loading={false}
                  />
                </Box>
              </Grid>
              {isFocus && (
                <React.Fragment>
                  <Box className="inputtextAreaContainer">
                    <Input
                      type={"textArea"}
                      inputConfig={{
                        label: "Notes",
                        type: "text",
                        name: "note",
                        id: "note",
                        placeholder: "Add note here...",
                      }}
                      inputStyle={{ height: "40px !important" }}
                      labelStyle={{ fontWeight: "bold" }}
                      autoFocus={true}
                    />
                  </Box>
                  <Flex justifyContent="flex-end">
                    <Button
                      backgroundColor="#fff"
                      border="1px #BCCCDC solid"
                      onClick={() => setIsFocus(false)}
                    >
                      Cancel
                    </Button>
                    <PrimaryButton
                      isLoading={isSubmitting}
                      onClick={handleSubmit}
                      disabled={!isValid}
                      marginLeft="15px"
                    >
                      <Text>Add note</Text>
                    </PrimaryButton>
                  </Flex>
                </React.Fragment>
              )}
              {!isFocus && (
                <Box onClick={() => setIsFocus(true)}>
                  <Input
                    type="input"
                    inputConfig={{
                      label: "Notes",
                      type: "text",
                      name: "note",
                      id: "note",
                      placeholder: "Add note here...",
                    }}
                    labelStyle={{ fontWeight: "bold" }}
                  />
                </Box>
              )}
            </React.Fragment>
          )}
        </Formik>
      )}
      {cart?.agentNote?.map((note) => (
        <Box marginY="20px">
          {note.createdAt && (
            <Text color="#486581" fontWeight={500} fontSize="13px">
              {moment(note?.createdAt).format("DD MMM")} {moment(note?.createdAt).format("hh:mm A")}
            </Text>
          )}
          {note.note && <Text>{note.note}</Text>}
        </Box>
      ))}
    </React.Fragment>
  );
};
