import { isProd } from "vendor/environment";

export const BASE_URL: string = process.env.REACT_APP_BASE_URL;
export const PUBLIC_BASE_URL: string = process.env.REACT_APP_PUBLIC_BASE_URL;

export const getCustomerStoreUrl = (domain?: string): string => {
  if (isProd()) return `https://${domain ? domain : "samedaycustom.com"}`;
  return `https://store-staging.${domain ? domain : "samedaycustom.com"}`;
};

export const getCustomerStoreWLUrl = (handle: string, domain?: string): string => {
  const url = getCustomerStoreUrl(domain);
  return url + `/wl/${handle}`;
};

export const socketEndpoint: string = isProd()
  ? "https://socketsdc.com"
  : "https://dev.socketsdc.com";

export const ioApi: string = socketEndpoint + "/order";

export const messageApi: string = socketEndpoint;

export const EVENTS = {
  CANCELLED_ORDER: "CANCELLED_ORDER",
  REASSIGNED_ORDER: "REASSIGNED_ORDER",
  MESSAGE: "MESSAGE",
  NOTES: "NOTES",
  MESSAGE_NOTIFICATION: "MESSAGE_NOTIFICATION",
  REASSIGNED_REMOVE_ORDER: "REASSIGNED_REMOVE_ORDER",
  DELIVERY_STATUS_UPDATE: "delivery-status-update",
  EDITED_PAYOUT_VALUE: "EDITED_PAYOUT_VALUE",
  ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE: "ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE",
};
export const responseDataurl =
  "https://script.google.com/macros/s/AKfycbxFHcLn1jMkj8BcL9ICy3IqhhrNv8U_RlcKDEnfr1J1-4Ev_70/exec";

export const contactDataUrl =
  "https://script.google.com/macros/s/AKfycbwbWXb9ncrXDaNTnN9N9vRNlc5gWh3ApWUX17FPP0VAJEcP6JA/exec";

export default {
  BEAM_AUTH: `${BASE_URL}/pusher/beam-auth`,
  CREATE_VENDORS: `${BASE_URL}/signup`,
  LOGIN_VENDOR: `${BASE_URL}/auth/login`,
  FORGOT_PASSWORD: `${BASE_URL}/auth/pwd/forgot`,
  EMAIL_VERIFY: `${BASE_URL}/auth/email/verify`,
  EMAIL_REVERIFY: `${BASE_URL}/auth/email/reverify`,
  CONTENT_TYPE: "application/json",
  STORE_USER_API: `${BASE_URL}/users`,
  STORE_ROLE_API: `${BASE_URL}/roles`,
  STORE_USER_ACTION_API: `${BASE_URL}/stores`,
  USER_STORE_ACTION_API: `${BASE_URL}/users`,
  USER_PROFILE_API: `${BASE_URL}/users/profile`,
  USER_PWD_CHANGE_API: `${BASE_URL}/users/profile/pwd`,
  BUSINESS_PROFILE_API: `${BASE_URL}/vendors`,
  CHECK_EMAIL_AVAILABILITY_API: `${BASE_URL}/etc`,
  ORDERS: `${BASE_URL}/orders`,

  DELIVERY: `${BASE_URL}/delivery`,
  PAYMENT: `${BASE_URL}/payment`,
  UPDATE_STORE_PROFILE: `${BASE_URL}/stores`,
  UPDATE_STORE_PROFILE_IMAGE: `${BASE_URL}/uploads/store/profile`,
  UPDATE_WORK_HOURS: `${BASE_URL}/stores/hour`,
  GET_WORK_HOURS: `${BASE_URL}/stores`,
  HOLIDAY_HOURS: `${BASE_URL}/stores/holiday-hours`,
  UPDATE_DELIVERY_METHOD: `${"https://us-central1-vendors-265809.cloudfunctions.net/v1"}/stores/delivery`,
  UPDATE_PRODUCTION_SPEED: `${BASE_URL}/stores/speed`,
  UPDATE_PRINTING_METHOD: `${BASE_URL}/stores/printing`,
  DELETE_PRODUCTION_SPEED: `${BASE_URL}/stores/speed`,

  GET_STORES: `${BASE_URL}/stores`,
  GET_DELIVERY_METHOD: `${"https://us-central1-vendors-265809.cloudfunctions.net/v1"}/stores/delivery`,
  GET_PRODUCTION_SPEED: `${BASE_URL}/stores/speed`,
  GET_PRINTING_METHODS: `${BASE_URL}/stores/printing`,
  GET_OPERATION_ORDERS: `${BASE_URL}/orders`,
  SEND_FEEDBACK: `${BASE_URL}/feedback`,
  GET_OPERATION_SERIES: `${BASE_URL}/series`,
  GET_OPERATION_TIMELINE: `${BASE_URL}/timeline`,
  GET_OPERATION_PRODUCTIONS: `${BASE_URL}/production`,
  GET_OPERATION_ARTWORK: `${BASE_URL}/artwork`,
  GET_OPERATION_DELIVERY: `${BASE_URL}/delivery`,
  GET_WEBSHOP_SALES_AGGREGATE: `${BASE_URL}/report/order/agg`,
  GET_WEBSHOP_SALES_SERIES: `${BASE_URL}/report/order/series`,
  GET_WEBSHOP_SALES: `${BASE_URL}/report/order`,
  CREATE_PASSWORD: `${BASE_URL}/auth/pwd`,
  DEFAULTS: `${BASE_URL}/defaults`,
  ABANDONED_CARTS: `${BASE_URL}/carts`,
  CUSTOMERS: `${BASE_URL}/customers`,
  QUERY_REQUEST: `${BASE_URL}/quote-request`,
  responseCode: {
    SUCCESS: 200,
  },

  STORE_LOCATIONS_API: `${BASE_URL}/stores`,

  // uploads
  PROFILE_IMG_UPLOAD: `${BASE_URL}/uploads/profiles`,
  NOTE_IMAGE_UPLOAD: `${BASE_URL}/uploads/notes`,
  PICKUP_CONFIRMATION_IMAGE_UPLOAD: `${BASE_URL}/uploads/confirmation`,

  // dashboard pages api
  SETUP_DATA_API: `${BASE_URL}/setup`,
  DASHBOARD_DATA_API: `${BASE_URL}/series/orders`,
  DASHBOARD_DATA_API_ALL: `${BASE_URL}/series/orders/all`,

  // accepting orders
  ACCEPTING_ORDERS_API: `${BASE_URL}/stores`,

  // messages
  MESSAGING: `${BASE_URL}/messages`,

  NOTIFICATION: `${BASE_URL}/notification`,

  STORE_MESSAGING: `${BASE_URL}/stores/message`,

  // edit notes
  EDIT_INTERNAL_NOTE: `${BASE_URL}/orders`,

  // fetch qr token
  FETCH_QR_TOKEN: `${BASE_URL}/qr`,

  // pusher authentication
  PUSHER_AUTH: `${BASE_URL}/pusher/auth`,

  QR_SCANNER_PORTAL: isProd()
    ? "https://master-sdc-pickup-confirmation.firebaseapp.com/"
    : "https://dev-sdc-pickup-confirmation.firebaseapp.com/",

  /**
   *
   * pickup confirmation blank document url
   */
  PICKUPCONFIRMATION_BLANK_DOCUMENT_URL:
    "https://firebasestorage.googleapis.com/v0/b/vendors-265809.appspot.com/o/Other%20Files%2FPickup%20Reciept%20Blank.pdf?alt=media&token=d55299f7-519f-4c31-8545-726f75a96715",

  // Defer prod speed api
  getDeferProdSpeedApi: (locationId: string) => `${BASE_URL}/stores/defer-prod-speed/${locationId}`,
};
